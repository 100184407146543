export default function () {
  const tooltips: Record<string, string> = {
    schedule: `Pace and number of hours.`,
    delivery: `Method of course delivery.`,
    qualificationLevel: `Refine results by qualification level.`,
    distance: `Refine results by distance from a suburb or postcode.`,
    duration: `Total time required to complete a course.`,
    'on campus': `Learn in a face-to-face environment at a TAFE NSW campus.`,
    online: `Learn online at anytime with 24/7 access to study materials.`,
    workplace: `Training at your workplace or in simulated workplace settings.`,
    combination: `Learn via a mix of on campus, online, and workplace training.`,
    'virtual classroom on campus':
      'Connect with your teacher and other students in real-time via web-conferencing at scheduled class times. You may be required to attend campus for some units.',
    'virtual classroom off campus':
      'Connect with your teacher and other students in real-time via web-conferencing at scheduled class times.',
    nationallyRecognisedTraining: `These courses are nationally standardised so you can train in one state and take a work opportunity in another state without needing to upskill.`,
    nonNationallyRecognisedTraining: `These short courses can’t be used for credit transfer but they’ve been specially designed by TAFE NSW teachers to deliver the skills you need.`,
    subsidised: `If you meet the eligibility requirements, the government will pay a part of the course fees on your behalf, and you pay the gap. There are some fully subsidised courses (no cost to you), including fee-free apprenticeships.`,
    fullTimeShare: `Full-time workers usually work 35 hours or more a week (in all their jobs combined).`,
    employmentSize: `Employment size is the number of workers who do this as their main job.`,
    averageAge: `This is the average age of all workers in this job.`,
    weeklyPay: `Median earnings are shown for full-time, non-managerial employees paid at the adult rate, before tax or any amounts that are salary sacrificed. These figures are a guide to earnings only and should not be used to determine a wage rate.`,
    averageFullTime: `The average full-time hours that people in this role work each week.`,
    skillLevelRating: `
    Skill level ratings are based on the range and complexity of job roles. In general, the higher the skill level, the more formal education and training, previous experience or on-the-job training needed to be good at the job.`,
    futureGrowth: `The Department of Employment, Skills, Small and Family Business estimates the likely change in number of workers in this role, or industry, over the next 5 years. Future growth is the likely percentage change compared to all other job roles.`,
    startDate: `First day of attendance online or on campus.`,
    hedCloseDate: `Last day for applications`,
    hedStartDate: `Last day for first round applications`,
    endDate: `Final day of attendance online or on campus.`,
    hedEndDate: `Last day for second round applications`,
    studyCommitment: `The average amount of time each week you’ll spend in class attendance and participation, directed study, and any required work placements. You may also need to do additional hours of self-directed study.`,
    additionalStudyTime: `The minimum recommended hours you will need to commit to your studies outside of class hours. This may include private study, work placements and research.`,
    classTime: `Dedicated hours required each week in either online or on campus classes.`,
    'full time': `16 hours or more per week.`,
    'part time': `Up to 15 hours per week.`,
    'self paced': `Set your own pace and choose when you study.`,
    variable: `A combination of full time, part time and self-paced`,
    careerPathways: ``,
    feeRange: `This is the fee you'll pay for the course. You may be able to pay in instalments if your course length is greater than 3 months.`,
    hedFeeRange: `This is an indicative fee. The course fee depends on the subjects you choose to study.`,
    fullFee: `The full course fee applies if you are not eligible for any subsidies or scholarships. This fee does not include additional costs for materials or uniforms that might be needed for this course.`,
    lms: `LMS help text`,
    feeFreeRange:
      'The fee range shows the maximum fee payable for this course. You may qualify for a range of subsidies including a full exemption. <br><br>' +
      'Continue and check fee details to answer eligibility questions and calculate your personalised course fee.',
    feeFreeFullFee: `This is the maximum fee you'll pay if you do not qualify for a subsidy.`,
    feeFreeSubsidy:
      'Fee-Free TAFE is fully funded by the NSW and commonwealth governments.',
    multipleStudyOptions: `View this course for more details about the different study options.`
  }

  const locationSearchRadius = 3
  const locationFilterRadius = 15

  const sitecore = {
    linkTypeInternal: 'internal',
    linkTypeExternal: 'external',
    internalLinkspath: '/Data/Data-Sources/Internal-Links'
  }

  const NNR_FUND_CODE = '841'
  const HED_FUND_CODE = '838'

  const PHONE_REGEX = /^\D*(\d\D*){9,14}$/i
  const EMAIL_REGEX =
    /(^[A-Z0-9!#\$%&'\*\+\-\/=\?\^_`\{\|\}~][A-Z0-9!#\$%&'\*\+\-\/=\?\^_`\{\|\}~\.]{0,62}@(([A-Z0-9](?:[A-Z0-9\-]{0,61}[A-Z0-9])?)(\.[A-Z0-9](?:[A-Z0-9\-]{0,61}[A-Z0-9])?)+)$)/i

  const SAME_OR_PAST_DATES = ['registrationDate', 'printedDate']
  const EXPIRY_IN_MINUTES = 45

  const CERT_2 = 5
  const DIPLOMA = 8
  const ADV_DIPLOMA = 9
  const schedule = {
    fullTime: 'Full Time',
    partTime: 'Part Time',
    selfPaced: 'Self paced'
  }

  const staticContent = {
    scheduleFullTime: `This timetable is based on the previous delivery of this course and is subject to change (depending on the availability of facilities and teacher schedules). Weekly timetables can also change between semesters.`,
    schedulePartTime: `This course is currently scheduled for 3 days a week. Your individual timetable will be provided to you after you enrol. This will confirm your study timetable, course units and assessments.`,
    scheduleSelfPaced: `The minimum number of study hours you’ll need to spend each week. This is in addition to your class hours and could include private study, work placement or research.`
  }

  return {
    schedule,
    staticContent,
    tooltips,
    sitecore,
    locationSearchRadius,
    locationFilterRadius,
    NNR_FUND_CODE,
    HED_FUND_CODE,
    PHONE_REGEX,
    EMAIL_REGEX,
    SAME_OR_PAST_DATES,
    EXPIRY_IN_MINUTES,
    CERT_2,
    DIPLOMA,
    ADV_DIPLOMA
  }
}
